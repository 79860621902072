<script setup lang="ts">
const navigation = useNavigation()

const showNotification = ref(false)

const flash = useFlash()
watchEffect(() => {
  if (flash.value?.banner) {
    showNotification.value = true
  }
})
</script>

<template>
  <div class="flex h-full">
    <div class="flex-none">
      <SidebarNavigation :items="navigation!" />
    </div>

    <main class="relative isolate min-h-dvh min-w-0 grow overflow-y-auto">
      <slot />

      <div class="pointer-events-none absolute inset-x-0 bottom-4 flex justify-center">
        <div
          class="pointer-events-auto rounded-full bg-gray-50 px-4 py-2 text-sm text-zinc-600 shadow"
        >
          Made with ❤️ by
          <a
            href="https://clickbar.dev"
            target="_blank"
            rel="noopener noreferrer"
            class="underline-offset-2 hover:underline"
            >clickbar.</a
          >
        </div>
      </div>
    </main>

    <TuiNotificationHost alignment="top-right" class="z-20" />

    <TuiModalFunctionsHost />

    <TuiFlashNotification
      v-if="flash"
      v-model:show="showNotification"
      class="z-20"
      :title="flash.banner"
      :type="flash.bannerStyle"
    />
  </div>
</template>
