import '@clickbar/tailwindui-vue/style.css'
import '@fontsource-variable/inter'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { trail } from 'momentum-trail'
import { createApp, h } from 'vue'

import SidebarLayoutVue from './Layouts/SidebarLayout.vue'
import '../css/app.css'

import type { DefineComponent } from 'vue'

const appName = window.document.querySelectorAll('title')[0].textContent ?? 'Laravel'

if ('serviceWorker' in navigator) {
  void navigator.serviceWorker.register('/sw.js', { scope: '/' })
}

void createInertiaApp({
  title: (title: string) => `${title} - ${appName}`,
  progress: {
    color: '#4B5563',
  },
  resolve: async (name: string) => {
    const page = await resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob<DefineComponent>('./Pages/**/*.vue'),
    )

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    page.default.layout ||= SidebarLayoutVue

    return page
  },
  setup({ el, App, props, plugin }) {
    const appInstance = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(autoAnimatePlugin)
      // @ts-expect-error TODO: Switch to ziggy
      .use(trail, { routes: null })
      .component('inertia-link', Link)

    appInstance.mount(el)
  },
})
